import React from "react"
import Page from 'components/Page'
import Layout from "components/Layout"
import useStoryblok from 'utils/storyblok'
import { PageContext } from "utils/context";

export default function PageIndex({ pageContext, location }) {
    const story = useStoryblok(pageContext.story, location)

    return (
      <PageContext.Provider
        value={{
          ...story,
          ...pageContext,
          location: location,
        }}
      >
        <Layout location={location}>
          <Page blok={story} />
        </Layout>
      </PageContext.Provider>
    )
}